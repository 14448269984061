<template>
	<Navbar :darkModeEnabled="darkModeEnabled" :toggleDarkMode="toggleDarkMode" />
	<router-view :darkModeEnabled="darkModeEnabled" />
	<Footer />
</template>

<script>
import Navbar from './components/Navbar';
import Footer from './components/Footer';
export default {
  components: {
	Navbar,
	Footer,
  },
  data() {
	return {
		darkModeEnabled: window.matchMedia('(prefers-color-scheme: dark').matches
			|| (!window.matchMedia('(prefers-color-scheme: dark').matches && localStorage.getItem('pc-theme') === null),
	}
  },
  watch: {
		darkModeEnabled(isEnabled) {
			if (isEnabled) {
				localStorage.removeItem('pc-theme');
				document.getElementsByTagName('html')[0].classList.remove('theme-light');
			} else {
				localStorage.setItem('pc-theme', 'light');
				document.getElementsByTagName('html')[0].classList.add('theme-light');
			}
		},
	},
	methods: {
		toggleDarkMode() { this.darkModeEnabled = !this.darkModeEnabled },
	}
}
</script>

<style lang="scss">
@import './scss/global.scss';

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	.btn-link {
		text-decoration: none;
	}
}
</style>
