<template>
    <div class="text-right bg-secondary text-light px-4 py-2 small">
        created with Vue.js
		•
        <a class="text-light" href="https://www.icons8.com" target="_blank" rel="noopener noreferrer">Icons8</a>
		•
        &copy; Pranav Chary, {{ year }}
    </div>
</template>

<script>
export default {
    data: function() {
        return { year: new Date().getFullYear() }
    }
}
</script>

<style lang="scss" scoped>
    a,
	a:hover,
	a:link,
	a.visited {
        text-decoration: none;
    }
</style>