<template>
    <nav class="navbar navbar-expand-md navbar-dark bg-secondary">
		<button
			type="button"
			class="btn btn-link text-light"
			:class="darkModeEnabled ? 'mode-d' : 'mode-l'"
			@click="toggleDarkMode()"
		>
			<img
				src="/images/dark-mode.png"
				alt="Dark mode"
				title="Enable light mode"
				:class="{ 'd-none': !darkModeEnabled }"
			/>
			<img
				src="/images/light-mode.png"
				alt="Light mode"
				title="Enable dark mode"
				:class="{ 'd-none': darkModeEnabled }"
			/>
		</button>
        <button
            type="button"
            class="navbar-toggler"
            @click="toggleNavItems()"
            data-toggle="collapse"
            data-target="#navbar"
            aria-controls="navbar-items"
            aria-label="Toggle navigation items"
            :aria-expanded="navItemsVisible.toString()"
        >
            <span class="navbar-toggler-icon"></span>
        </button>
        <div
            class="navbar-collapse collapse"
            :class="{ show: this.navItemsVisible }"
            id="navbar-items"
        >
            <ul class="navbar-nav justify-content-end">
                <li
                    class="nav-item"
                    v-for="item in navItems"
                    :key="`navitem-${item.scrollTo}`"
                >
                    <button
                        type="button"
                        class="btn btn-link nav-link active btn-block text-left text-md-center"
                        @click="scrollToSection(item.scrollTo)"
                    >
                        {{ item.label }}
                    </button>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
	mounted() {
		if (this.darkModeEnabled) {
			document.getElementsByTagName('html')[0].classList.remove('theme-light');
		} else {
			document.getElementsByTagName('html')[0].classList.add('theme-light')
		}
	},
	props: {
		darkModeEnabled: {
			type: Boolean,
			default: true,
		},
		toggleDarkMode: {
			type: Function,
			required: true,
		},
	},
    data () {
        return {
            navItemsVisible: false,
            navItems: [
                { label: 'About Me', scrollTo: 'about' },
                { label: 'Skillset', scrollTo: 'skillset' },
                { label: 'Projects', scrollTo: 'projects' },
                { label: 'Connect', scrollTo: 'connect' }
            ],
        }
    },
    methods: {
        toggleNavItems() { this.navItemsVisible = !this.navItemsVisible },
        scrollToSection(id) {
            if (document.getElementById(id)) {
                document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
            } else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    nav.navbar {
        button:focus {
            outline: none;
            box-shadow: none;
        }

        img {
            width: 2rem;
        }

		.mode-d,
		.mode-l {
			position: relative;

			img[alt="Dark mode"] {
				transform: rotate(35deg);
			}
		
			&:after {
				position: absolute;
				text-align: left;
				top: 25%;
				bottom: 25%;
				left: 90%;
				right: 0;
				width: 100px;
				transform: scale3d(0, 1, 1);
				opacity: 0;
				transform-origin: 0% 50%;
				transition: transform .2s ease-out, opacity 0.1s;
			}

			&:hover:after {
				opacity: .85;
				transform: scale3d(1, 1, 1);
			}
		}

		.mode-d:after {
			content: "Dark mode";
		}
		.mode-l:after {
			content: "Light mode";
		}

		// .view-mode:hover > span {
		// 	transform: scale3d(1, 1, 1);
		// 	transition: transform 0.2s ease-out;
		// }

        .navbar-nav {
            flex: 1;
        }
    }
</style>